import FrameMain from '../components/frameMain/FrameMain';
import i18n from 'components/i18n';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import Error404 from '../screens/errors/404/404';

import PublicRouter from './publicRouter';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { IRegionOptionListItem } from '../interfaces/checkout/address/IRegionOptionListItem';
import { IAgreementsResponse } from '../interfaces/payment/IAgreementsResponse';
import LoadingApp from 'vkid-ui/lib/Components/LoadingApp';
import useErrorState from '../components/error/Error';
import { useState } from 'react';
import { IAdsConfig } from '../interfaces/checkout/address/IAdsConfig';
import { IInbankRentalSummary } from '../interfaces/payment/IInbankRentalSummary';
import { IMontonio } from 'components/Checkout/components/checkout/Payment/Methods/interfaces/IMontonio';

export const RouteNotFound = () => <Redirect to={{ state: { notFoundError: true } }} />;

export const Main = (props) =>
    props.location && props.location.state && props.location.state.notFoundError ? (
        <FrameMain {...props}>
            <Error404 />
        </FrameMain>
    ) : (
        <FrameMain {...props} />
    );

export const CaptureRouteNotFound = withRouter(Main);

export const LocaleContext = React.createContext('en');

const mapStateToProps = (state) => {
    let translations = state.entities.translations;
    if (typeof translations !== 'undefined') {
        translations = JSON.parse(translations);
    }
    return {
        translations,
        locale: typeof translations === 'object' ? Object.keys(translations).pop() : undefined,
    };
};

declare global {
    interface Window {
        regions: IRegionOptionListItem[];
        jwt: string;
        quoteIdMask: string;
        API_URL: string;
        form_key: string;
        LOGIN_URL: string;
        STORE_CODE: string;
        PHONE_PREFIXES: string[];
        detailedPaymentEnabled: boolean;
        montonioConfig: {
            cnf: IMontonio;
            applePayLogo?: string;
            googlePayLogo?: string;
            cardPaymentLogo?: string;
        };
        paymentLogos: {
            payment: any;
        };
        checkoutConfig?: {
            logo_src?: string;
            business_address_form?: {
                disable_contact_info: boolean;
                fields_sort_order: {
                    [key: string]: number;
                };
            };
        };
        agreements: IAgreementsResponse;
        locationDefaults: {
            country_id: string;
            region_id: number;
        };
        mkCardCancelled: () => void;
        mkCardCompleted: (token) => void;
        isBusinessEnabled: boolean;
        isLoginEnabled: boolean;
        inAddressConfiguration: IAdsConfig;
        rootElement: string;
        isTrhEnabled: boolean;
        messageWidgetUrl: string;
        isSubscriptionEnabled?: boolean;
        isPersonalCodeEnabled?: boolean;
        isPersonalCodeBottomLocated?: boolean;
        isPersonalIdSet: boolean;
        personalCodeRequiredFor?: string[];
        isInbankRental?: boolean;
        isRenewalChecked?: boolean;
        isRenewalAllowed?: boolean;
        renewalLabel?: string;
        inbankRentalSummary?: IInbankRentalSummary;
        allowCheckMoAll?: boolean;
        personalCodeValidationRules?: {
            [key: string]: string;
        };
        companyCodeValidationRules?: {
            [key: string]: string;
        };
    }
}
const MainRouter = () => {
    const translationsRequest = useRequest(
        request({ type: 'translations', url: 'fast/checkout/translations', notApi: true }),
    );
    const { translations, locale } = useSelector(mapStateToProps);

    if (locale && typeof locale !== 'undefined') {
        i18n(translations).changeLanguage(locale);
    }
    const [isErrorState, setIsErrorState] = useState(false);
    const setError = (error: boolean) => {
        if (isErrorState !== error) {
            setIsErrorState(error);
        }
    };
    const { errorComponent } = useErrorState({ setError });

    if (translationsRequest && translationsRequest[0].isFinished && !locale) {
        setError(true);
    }

    return (
        <React.Fragment>
            {isErrorState && errorComponent}
            {translationsRequest && translationsRequest[0].isFinished && locale ? (
                <LocaleContext.Provider value={locale}>
                    <CaptureRouteNotFound>
                        <React.Suspense fallback={<LoadingApp />}>
                            <Switch>
                                <Route path="/" component={PublicRouter} />
                                <RouteNotFound />
                            </Switch>
                        </React.Suspense>
                    </CaptureRouteNotFound>
                </LocaleContext.Provider>
            ) : (
                <LoadingApp />
            )}
        </React.Fragment>
    );
};
export default MainRouter;
