import React from 'react';
import { IPaymentMethodProps } from 'components/Checkout/components/checkout/Payment/PaymentBlock';
import { IPaymentMethodResponse } from 'components/Checkout/interfaces/payment/IPaymentMethodResponse';
import SimplePayment from 'components/Checkout/components/checkout/Payment/Methods/SimplePayment';
import { useMutation } from 'redux-query-react';
import { postRequest } from 'data/requests/postRequest';
import { isLoggedIn } from 'components/Checkout/helpers/customer/isLoggedIn';
import { PaymentMethodEnum } from 'components/Checkout/components/checkout/Payment/Methods/MethodHandler';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const MontonioCard = (props: IProps) => {
    const { paymentMethodProps, method, email, setAllowQuery, extensionAttributes } = props;

    const methodConfig = window.montonioConfig?.cnf;
    const defaultRegion = methodConfig?.defaultRegion;

    const [{}, postData] = useMutation((data) =>
        postRequest({
            type: 'setPayment',
            url: isLoggedIn
                ? 'carts/mine/set-payment-information'
                : `guest-carts/${window.quoteIdMask}/set-payment-information`,
            data,
            useStoreCode: true,
        }),
    );

    const redirect = { to: `checkout/onepage/success` };

    const logoUrl = () => {
        switch (method.code) {
            case PaymentMethodEnum.montonio_payments_card:
                return window.montonioConfig.cardPaymentLogo;
            case PaymentMethodEnum.montonio_payments_card_applepay:
                return window.montonioConfig.applePayLogo;
            case PaymentMethodEnum.montonio_payments_card_googlepay:
                return window.montonioConfig.googlePayLogo;
        }

        return;
    };

    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => {
                setAllowQuery(true);
                postData({
                    cartId: window.quoteIdMask,
                    email,
                    paymentMethod: {
                        method: method.code,
                        extension_attributes: extensionAttributes ? extensionAttributes() : [],
                        additional_data: {
                            bank_id: method.code,
                            pref_region: defaultRegion,
                        },
                    },
                }).then((response) => {
                    setAllowQuery(false);
                    if (response.body === true || response.body === 'true') {
                        props.paymentMethodProps.onClick({ redirect });
                    }
                });
            }}
            label={logoUrl() ? '' : method.title}
            logoUrl={logoUrl() ?? ''}
            paymentMethodProps={props.paymentMethodProps}
            style={{ backgroundSize: 'contain', backgroundPosition: '50% 50%', backgroundRepeat: 'norepeat' }}
            description={method.title}
        />
    );
};

export default MontonioCard;
